<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <!-- <div class="d-flex flex-column-auto flex-column pt-lg-20 pt-15">
          <a href="#" class="text-center mb-3">
            <img
              src="/img/logo/Logo-UNJ.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h5
            class="font-weight-light text-center font-size-h4 font-size-h6-lg text-white mb-6"
          >
            Menjadikan Sekolah yang berwawasan lingkungan
            dalam membangun insan yang cerdas dan kompetitif
          </h5>
        </div> -->
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          "
          :style="{
            backgroundImage: `url(${background_image})`,
            backgroundSize: 'cover',
          }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="checkUserActivated()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <!-- <div class="row"> -->
                <div class="d-flex align-items-center mb-2">
                  <div class="">
                    <img
                      :src="`${logo}`"
                      alt="LOGO"
                      width="100"
                    />
                  </div>
                  <div class="ml-1 ml-sm-2">
                    <h3 class="text-dark font-size-h2 font-size-h2-lg">
                      <!-- <span style="color: #244ca3" class="d-block"
                        >Sistem Informasi Terpadu</span
                      >
                      <span
                        style="color: #244ca3; font-family: Poppins, sans-serif"
                        class="d-block font-weight-bolder"
                        >Fisika UNJ</span
                      > -->
                      <span style="color: #244ca3" class="d-block"
                        >{{app_name}}</span
                      >
                    </h3>
                  </div>
                </div>
                <em class="mt-2 text-primary font-weight-bold">Sistem ini adalah sistem terintegrasi kolaborasi tiga program studi (Program studi S1 Fisika, Program studi S1Pendidikan Fisika, dan Program studi S2 Pendidikan Fisika)</em>
                <!-- </div> -->
                <!-- <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  <p style="text-align: justify">
                    <img
                      src="/img/login/SMKN4_bersih.png"
                      style="float: left; margin: 0 9px 3px 0"
                      alt="LOGO"
                      width="140"
                    /><span class="d-block">Learning</span> <span class="d-block">Management</span> <span>System</span>
                  </p>
                </h3> -->
                <!-- <span class="text-muted font-weight-bold font-size-h4"
                  >New Here?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Create an Account</a
                  ></span> -->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >NIP (Dosen) atau NIM (Mahasiswa)</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="email"
                    ref="email"
                    placeholder="NIP (Dosen) atau NIM (Mahasiswa)"
                    v-model="form.email"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <!-- <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    style="color: #244ca3 !important"
                    >Forgot Password ?</a
                  > -->
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <div class="input-group mb-2">
                    <input
                      class="
                        form-control form-control-solid
                        h-auto
                        py-7
                        px-6
                        rounded-lg
                      "
                      :type="passwordIsVisible"
                      placeholder="Password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                    />
                    <div
                      class="input-group-prepend"
                      @click="
                        passwordIsVisible == 'password'
                          ? (passwordIsVisible = 'text')
                          : (passwordIsVisible = 'password')
                      "
                    >
                      <div
                        class="input-group-text"
                        style="border: none; cursor: pointer"
                      >
                        <template v-if="passwordIsVisible == 'password'">
                          <b-icon-eye-slash></b-icon-eye-slash>
                        </template>
                        <template v-if="passwordIsVisible == 'text'">
                          <b-icon-eye></b-icon-eye>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">@</div>
                    </div>
                    <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="Username">
                  </div> -->
                </div>
              </div>
              <!-- <vue-recaptcha
                  sitekey="6Ld_gkUdAAAAAH2TEVfgJ67-wVUdPnPeBtoP2w0k"
                  @verify="verifyRecaptcha"
                  @expired="expiredRecaptcha"
                  @render="renderRecaptcha"
                  :loadRecaptchaScript="true"
                ></vue-recaptcha> -->
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                  style="background-color: #244ca3; border-color: #244ca3"
                >
                  Sign In
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
              <span class="text-muted font-weight-bold font-size-h4"
                >Belum punya akun?
                <a
                  id="kt_login_signup"
                  class="text-primary font-weight-bolder"
                  @click="showForm('signup')"
                  style="cursor: pointer"
                  >Daftar Mahasiswa</a
                ></span
              >
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Daftar Mahasiswa
                </h3>
                <!-- <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p> -->
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="NIM"
                  name="registration_number"
                  ref="registration_number"
                  autocomplete="off"
                  v-model="register.email"
                />
                <small class="text-danger">{{ error.email }}</small>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="text"
                  placeholder="Nama"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                  v-model="register.name"
                />
                <small class="text-danger">{{ error.name }}</small>
              </div>
              <div class="form-group">
                <!-- <treeselect
                    class="form-control form-control-solid  py-7 px-6 rounded-lg font-size-h6"
                    v-model="form.major_id"
                    :multiple="false"
                    placeholder="Pilih Prodi"
                    :options="majors"
                  /> -->
                <b-form-select
                  id="input-3"
                  placeholder="Prodi"
                  v-model="register.major_id"
                  name="major_id"
                  ref="major_id"
                  class="
                    form-control form-control-solid
                    h-auto
                    py-4
                    px-6
                    custom-select custom-select
                    rounded-lg
                    font-size-h6
                  "
                  :options="majors"
                ></b-form-select>
                <small class="text-danger">{{ error.major_id }}</small>
              </div>
              <div class="form-group">
                <b-form-select
                  id="input-4"
                  placeholder="Jenjang Pendidikan"
                  v-model="register.educational_stage_id"
                  name="educational_stage_id"
                  ref="educational_stage_id"
                  class="
                    form-control form-control-solid
                    h-auto
                    py-4
                    px-6
                    custom-select custom-select
                    rounded-lg
                    font-size-h6
                  "
                  :options="educational_stages"
                ></b-form-select>
                <small class="text-danger">{{
                  error.educational_stage_id
                }}</small>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="text"
                  placeholder="Nomor Telepon Seluler"
                  name="phone"
                  ref="phone"
                  autocomplete="off"
                  v-model="register.phone"
                />
                <small class="text-danger">{{ error.phone }}</small>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                  v-model="register.password"
                />
                <small class="text-danger">{{ error.password }}</small>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Konfirmasi Password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                  v-model="register.password_confirmation"
                />
                <small class="text-danger">{{
                  error.password_confirmation
                }}</small>
              </div>
              <!-- <div class="form-group">
                <label for="robot" class="col-sm-2 col-form-label"></label>
                <div class="col-sm-10">
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    sitekey="/*/*/*/*/*/"
                  >
                  </vue-recaptcha>
                </div>
              </div> -->
              <!-- <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div> -->
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-10
                    py-4
                    my-3
                    ml-2
                  "
                  style="width: 150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    ml-3
                  "
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="
            d-flex
            justify-content-lg-start justify-content-center
            align-items-end
            py-7 py-lg-5
          "
        >
          <!-- <a href="#" class="text-primary font-weight-bolder font-size-h5" style="color: #244ca3 !important"
            >Terms</a
          > -->
          <!-- <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5" style="color: #244ca3 !important"
            >Plans</a
          > -->
          <!-- <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5" style="color: #244ca3 !important"
            >Contact Us</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import ApiService from "@/core/services/api.service.js";
import JwtService from "@/core/services/jwt.service";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

// my plugin
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import VueRecaptcha from "vue-recaptcha";
// my script
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

// configuration
import { getConfiguration, setConfiguration } from "@/core/services/jwt.service.js";

export default {
  name: "login-1",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      configuration: getConfiguration(),
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      register: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: 2,
        registration_number: "",
        phone: "",
        major_id: "",
        educational_stage_id: "",
        photo: "/storage/users/default-user.svg",
        birt_date: null,
        robot: false,
      },

      error: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        major_id: "",
        educational_stage_id: "",
      },
      // other
      is_verify: false,
      majors: [
        { value: "", text: "Pilih Prodi", disabled: true },
        { value: 1, text: "Fisika" },
        { value: 2, text: "Magister Pendidikan Fisika" },
        { value: 3, text: "Pendidikan Fisika" },
      ],
      educational_stages: [
        { value: "", text: "Pilih Jenjang Pendidikan", disabled: true },
        { value: 1, text: "S1" },
        { value: 2, text: "S2" },
        { value: 3, text: "S3" },
      ],
      passwordIsVisible: "password",
      background_image: getConfiguration() != null ? getConfiguration().cover_image : '',
      logo: getConfiguration() != null ? getConfiguration().logo : '',
      app_name: getConfiguration() != null ? getConfiguration().app_name : ''
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        // process.env.BASE_URL + "media/svg/illustrations/login-visual-5.svg"
        process.env.BASE_URL + "img/login/bg-login-1.jpeg"
      );
    },
  },
  mounted() {
    if (this.background_image == '' || this.logo == '' || this.app_name) {
      this.getSetting()
    }

    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    // this.getMajorOption();

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "NIP (Dosen) atau NIM (Mahasiswa) wajib diisi",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password wajib diisi",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              // message: "Nama harus diisi",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              // message: "Email harus diisi",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        registration_number: {
          validators: {
            notEmpty: {
              // message: "NIM harus diisi",
            },
          },
        },
        phone: {
          validators: {
            notEmpty: {
              // message: "Nomor Telepon Seluler harus diisi",
            },
          },
        },
        major_id: {
          validators: {
            notEmpty: {
              // message: "Prodi harus diisi",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              // message: "Password harus diisi",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              // message: "Konfirmasi password harus diisi",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "Konfirmasi password dan password harus sama",
            },
          },
        },
        // agree: {
        //   validators: {
        //     notEmpty: {
        //       message: "You should agree terms and conditions",
        //     },
        //   },
        // },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    async getSetting() {
      let response = await module.get("api/settings-no-auth/1")
      if (response != null) {
        setConfiguration(JSON.stringify(response))
        this.background_image = response.cover_image
        this.logo = response.logo
        this.app_name = response.app_name
      }
    },

    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onVerify: function (response) {
      if (response) this.register.robot = true;
    },

    renderRecaptcha(evt) {},
    verifyRecaptcha(evt) {
      this.is_verify = true;
    },
    expiredRecaptcha(evt) {
      this.is_verify = false;
    },

    checkUserActivated() {
      SwalLoading.fire();
      ApiService.post("api/user-check-activated", this.form).then(
        (response) => {
          if (response.data.is_active) {
            this.onSubmitLogin();
          } else {
            SwalLoading.close();
            Swal.fire(response.data.title, response.data.message, "error");
          }
        }
      );

      // If Data Not Found
      // if (response == null) {
      //   this.$router.push('/masters/semesters')
      // } else {
      //   this.form['_method'] = 'put'
      //   this.dataLoaded = true
      // }
    },
    onSubmitLogin() {
      // this.fv.validate();

      // this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      // const submitButton = this.$refs["kt_login_signin_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      // submitButton.disabled = true

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            let access_right, role_right;
            ApiService.get("api/users/me").then((data) => {
              // //("user/me", data)
              ApiService.put(`api/users/${data.data.data.id}/set-user-on`)
                .then((response) => {
                  //('success set login')
                })
                .catch((error) => {
                  //('error set login')
                });
              ApiService.get("api/users/" + data.data.data.id).then(
                (response) => {
                  //("user access", response);
                  if (data.data.data) {
                    ApiService.get("api/roles/" + data.data.data.role_id).then(
                      (result) => {
                        //("role access", response);
                        access_right = response.data.meta.access_right_display;
                        role_right = result.data.meta.access_right_display;

                        let merge = [...access_right, ...role_right];
                        // //("merge", merge)

                        window.localStorage.setItem(
                          "access_right_display",
                          JSON.stringify(merge)
                        );

                        let user = data.data.data;
                        // user.access_right = access_right + ", " + role_right;
                        JwtService.setUser(JSON.stringify(user));

                        this.$router.push({ name: "dashboard" });
                        SwalLoading.close();
                      }
                    );
                  } else if (!data.data.data.position_id) {
                    window.localStorage.setItem(
                      "access_right",
                      response.data.meta.access_right
                    );

                    window.localStorage.setItem(
                      "access_right_display",
                      JSON.stringify(response.data.meta.access_right_display)
                    );

                    let user = data.data.data;
                    access_right = response.data.meta.access_right;
                    user.access_right = response.data.meta.access_right;
                    JwtService.setUser(JSON.stringify(user));

                    for (let z = 0; z < 20; z++) {
                      if (z == 19) {
                        this.$router.push({ name: "dashboard" });
                        SwalLoading.close();
                      }
                    }
                  }
                }
              );
            });
            // this.$router.push({ path: "dashboard" })
            // SwalLoading.close()
          });
        // submitButton.classList.remove(
        //   "spinner",
        //   "spinner-light",
        //   "spinner-right"
        // );
      }, 100);
      // });

      // this.fv.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary",
      //     heightAuto: false,
      //   });
      // });
    },

    async onSubmitRegister() {
      this.fv1.validate();

      // this.fv1.on("core.form.valid", () => {
      // const email = this.$refs.remail.value;
      // const password = this.$refs.rpassword.value;
      // const name = this.$refs.fullname.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      // const submitButton = this.$refs["kt_login_signup_submit"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // setTimeout(() => {
      // send register request
      // this.$store
      //   .dispatch(REGISTER, {
      //     email: this.register.email,
      //     name: this.register.name,
      //     password: this.register.password,
      //     password_confirmation: this.register.password_confirmation,
      //   })
      //   .then(() => this.$router.push({ name: "dashboard" }));

      this.userRegistration();

      // submitButton.classList.remove(
      //   "spinner",
      //   "spinner-light",
      //   "spinner-right"
      // );
      // }, 2000);
      // });

      // this.fv1.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Mohon periksa kembali form!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary",
      //     heightAuto: false,
      //   });
      // });
    },
    async userRegistration() {
      this.register.registration_number = this.register.email;

      let response = await module.submit(
        this.register,
        "api/user-registration"
      );

      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        console.log("success registration", response);
        this.register.registration_number = "";
        this.register.name = "";
        this.register.password = "";
        this.register.password_confirmation = "";
        this.register.email = "";
        this.showForm("signin");
      }
    },
    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.majors = response.data;
        this.majors.unshift({
          label: "Pilih Prodi",
          id: "",
          isDisabled: true,
        });
      }
    },
  },
};
</script>
